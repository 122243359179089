<template>
  <footer class="footer">
    <div class="footer-container">
      <p>© {{ currentYear }} 商品展示网站 版权所有</p>
      <p>ICP备案号: 京ICP备XXXXXXXX号-X</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 5px 0;
}
</style> 