<template>
  <div class="customer-service-container">
    <!-- 悬浮按钮 -->
    <div class="service-button" @click="toggleChat" :class="{ 'active': chatVisible }">
      <i class="el-icon-service"></i>
      <span>在线客服</span>
    </div>
    
    <!-- 聊天窗口 -->
    <transition name="slide-fade">
      <div v-show="chatVisible" class="chat-window">
        <div class="chat-header">
          <div class="chat-title">
            <i class="el-icon-service"></i>
            <span>在线客服</span>
          </div>
          <div class="chat-actions">
            <i class="el-icon-minus" @click="minimizeChat"></i>
            <i class="el-icon-close" @click="closeChat"></i>
          </div>
        </div>
        
        <div class="chat-body" ref="chatBody">
          <div class="chat-messages">
            <div class="message service">
              <div class="avatar">
                <i class="el-icon-service"></i>
              </div>
              <div class="content">
                <p>您好，欢迎咨询，请问有什么可以帮助您的？</p>
                <span class="time">{{ formatTime(Date.now()) }}</span>
              </div>
            </div>
            
            <div v-for="(msg, index) in messages" :key="index" 
                :class="['message', msg.type]">
              <div class="avatar" v-if="msg.type === 'service'">
                <i class="el-icon-service"></i>
              </div>
              <div class="content">
                <p>{{ msg.content }}</p>
                <span class="time">{{ formatTime(msg.time) }}</span>
              </div>
              <div class="avatar" v-if="msg.type === 'user'">
                <i class="el-icon-user"></i>
              </div>
            </div>
          </div>
        </div>
        
        <div class="chat-footer">
          <el-input
            v-model="messageInput"
            placeholder="请输入消息..."
            @keyup.enter.native="sendMessage"
          ></el-input>
          <el-button type="primary" @click="sendMessage">发送</el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CustomerService',
  data() {
    return {
      chatVisible: false,
      messageInput: '',
      messages: []
    }
  },
  methods: {
    toggleChat() {
      this.chatVisible = !this.chatVisible
    },
    minimizeChat() {
      this.chatVisible = false
    },
    closeChat() {
      this.chatVisible = false
    },
    sendMessage() {
      if (!this.messageInput.trim()) return
      
      // 添加用户消息
      this.messages.push({
        type: 'user',
        content: this.messageInput,
        time: Date.now()
      })
      
      const userMessage = this.messageInput
      this.messageInput = ''
      
      // 滚动到底部
      this.$nextTick(() => {
        this.scrollToBottom()
      })
      
      // 模拟客服回复
      setTimeout(() => {
        let reply = '感谢您的咨询，我们的客服人员会尽快处理您的问题。'
        
        if (userMessage.includes('价格') || userMessage.includes('多少钱')) {
          reply = '我们的产品价格在产品详情页面有详细说明，您可以在产品页面查看具体价格信息。'
        } else if (userMessage.includes('发货') || userMessage.includes('物流')) {
          reply = '我们通常在确认付款后24小时内发货，物流配送时间根据地区不同大约需要1-3天。'
        } else if (userMessage.includes('退货') || userMessage.includes('退款')) {
          reply = '我们支持7天无理由退货，商品需保持原包装和未使用状态，退货运费由买家承担。'
        } else if (userMessage.includes('你好') || userMessage.includes('您好') || userMessage.includes('在吗')) {
          reply = '您好，很高兴为您服务，请问有什么可以帮助您的？'
        }
        
        this.messages.push({
          type: 'service',
          content: reply,
          time: Date.now()
        })
        
        // 滚动到底部
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }, 1000)
    },
    formatTime(timestamp) {
      const date = new Date(timestamp)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${hours}:${minutes}`
    },
    scrollToBottom() {
      if (this.$refs.chatBody) {
        const chatBody = this.$refs.chatBody
        chatBody.scrollTop = chatBody.scrollHeight
      }
    }
  }
}
</script>

<style scoped>
.customer-service-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}

.service-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #409EFF;
  color: white;
  border-radius: 24px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.service-button:hover {
  background-color: #66b1ff;
}

.service-button.active {
  background-color: #66b1ff;
}

.service-button i {
  font-size: 20px;
  margin-right: 8px;
}

.chat-window {
  position: absolute;
  right: 0;
  bottom: 60px;
  width: 350px;
  height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #409EFF;
  color: white;
}

.chat-title {
  display: flex;
  align-items: center;
}

.chat-title i {
  font-size: 20px;
  margin-right: 8px;
}

.chat-actions {
  display: flex;
}

.chat-actions i {
  margin-left: 15px;
  cursor: pointer;
  font-size: 16px;
}

.chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #f9f9f9;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  margin-bottom: 15px;
}

.message.service {
  justify-content: flex-start;
}

.message.user {
  justify-content: flex-end;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #409EFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.message.user .avatar {
  background-color: #67C23A;
}

.content {
  max-width: 70%;
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.message.service .content {
  border-top-left-radius: 0;
}

.message.user .content {
  border-top-right-radius: 0;
}

.content p {
  margin: 0;
  word-break: break-word;
}

.time {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  text-align: right;
}

.chat-footer {
  padding: 10px;
  background-color: white;
  border-top: 1px solid #eee;
  display: flex;
}

.chat-footer .el-input {
  margin-right: 10px;
}

/* 动画效果 */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style> 