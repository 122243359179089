import Mock from 'mockjs'

const products = []
const categories = ['电子产品', '服装', '家居', '食品']

// 生成30个随机商品
for (let i = 1; i <= 30; i++) {
  const category = categories[Math.floor(Math.random() * categories.length)]
  products.push(Mock.mock({
    id: i,
    name: '@ctitle(5, 10)',
    price: '@float(100, 10000, 2, 2)',
    description: '@cparagraph(3, 7)',
    category,
    'images|3-6': ['@image("300x300", "#@color", "#FFF", "Product")'],
    mainImage: '@image("600x600", "#@color", "#FFF", "Product")',
    'skus|2-4': [
      {
        id: '@id',
        name: '@ctitle(2, 4)',
        price: '@float(100, 10000, 2, 2)',
        stock: '@integer(0, 100)'
      }
    ],
    createTime: '@datetime'
  }))
}

// 模拟获取商品列表
Mock.mock(/\/api\/products(\?.+)?$/, 'get', (options) => {
  const url = new URL('http://example.com' + options.url)
  const category = url.searchParams.get('category')
  
  if (category) {
    return {
      code: 200,
      data: products.filter(item => item.category === category)
    }
  }
  
  return {
    code: 200,
    data: products
  }
})

// 模拟获取商品详情
Mock.mock(/\/api\/products\/\d+/, 'get', (options) => {
  const id = parseInt(options.url.match(/\/api\/products\/(\d+)/)[1])
  const product = products.find(item => item.id === id)
  
  if (product) {
    return {
      code: 200,
      data: product
    }
  }
  
  return {
    code: 404,
    message: '商品不存在'
  }
})

export default Mock 