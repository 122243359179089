<template>
  <header class="header">
    <div class="header-container">
      <div class="logo">
        <router-link to="/">
          <h1>商品展示</h1>
        </router-link>
      </div>
      <el-menu
        :default-active="activeIndex"
        class="nav-menu"
        mode="horizontal"
        router
        background-color="#fff"
        text-color="#333"
        active-text-color="#409EFF"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/products">产品列表</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    activeIndex() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.header {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  padding: 10px 0;
}

.logo a {
  text-decoration: none;
  color: #333;
}

.nav-menu {
  border-bottom: none;
}
</style> 