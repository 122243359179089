import { getProducts, getProductById, getProductsByCategory } from '@/api/products'

const state = {
  products: [],
  product: null,
  categories: []
}

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_PRODUCT(state, product) {
    state.product = product
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  }
}

const actions = {
  async fetchProducts({ commit }) {
    try {
      const response = await getProducts()
      commit('SET_PRODUCTS', response.data)
      return response
    } catch (error) {
      console.error('获取产品列表失败:', error)
      return Promise.reject(error)
    }
  },
  async fetchProductById({ commit }, id) {
    try {
      const response = await getProductById(id)
      commit('SET_PRODUCT', response.data)
      return response
    } catch (error) {
      console.error('获取产品详情失败:', error)
      return Promise.reject(error)
    }
  },
  async fetchProductsByCategory({ commit }, category) {
    try {
      const response = await getProductsByCategory(category)
      commit('SET_PRODUCTS', response.data)
      return response
    } catch (error) {
      console.error('获取分类产品失败:', error)
      return Promise.reject(error)
    }
  },
  async fetchCategories({ commit }) {
    try {
      // 模拟获取分类
      const categories = [
        { id: 1, name: '电子产品' },
        { id: 2, name: '服装' },
        { id: 3, name: '家居' },
        { id: 4, name: '食品' }
      ]
      commit('SET_CATEGORIES', categories)
      return categories
    } catch (error) {
      console.error('获取分类失败:', error)
      return Promise.reject(error)
    }
  }
}

const getters = {
  allProducts: state => state.products,
  productDetail: state => state.product,
  allCategories: state => state.categories
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
} 