import request from '@/utils/request'

export function getProducts() {
  return request({
    url: '/api/products',
    method: 'get'
  })
}

export function getProductById(id) {
  return request({
    url: `/api/products/${id}`,
    method: 'get'
  })
}

export function getProductsByCategory(category) {
  return request({
    url: '/api/products',
    method: 'get',
    params: { category }
  })
} 